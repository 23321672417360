import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BackTop } from 'antd';
import {
    personalAreaPath,
    providerBotsPath,
    providerBotsCreatePath,
    providerBotsEditPath,
    providerBotsStatistics,
    ordersPath,
    createNewOrderPath,
    editOrderPath,
} from '../../../../constants';

import Aside from './aside';
import ProviderBotContainer from './provider-bot-page';
import CreateProviderBotContainer from './create-provider-bot-page';
import EditProviderBotContainer from './edit-provider-bot-page';
import ProviderStatisticsContainer from './provider-bot-statistics';
import OrdersContainer from './orders-page';
import CreateNewOrderContainer from './create-new-order-page';
import EditOrderContainer from './edit-order-page';

import style from './personal-area.module.scss';
import './personal-area.scss';
import top from '../../../assets/images/top.svg';

const PersonalAreaView = () => (
    <div className={style.personalAreaWrapper}>
        <Aside />
        <Switch>
            <Route
                path={`${personalAreaPath}${providerBotsPath}`}
                component={ProviderBotContainer}
            />
            <Route
                path={`${personalAreaPath}${providerBotsCreatePath}`}
                component={CreateProviderBotContainer}
            />
            <Route
                path={`${personalAreaPath}${providerBotsEditPath}/:id?`}
                component={EditProviderBotContainer}
            />
            <Route
                path={`${personalAreaPath}${providerBotsStatistics}/:id?`}
                component={ProviderStatisticsContainer}
            />
            <Route
                path={`${personalAreaPath}${ordersPath}`}
                component={OrdersContainer}
            />
            <Route
                path={`${personalAreaPath}${createNewOrderPath}`}
                component={CreateNewOrderContainer}
            />
            <Route
                path={`${personalAreaPath}${editOrderPath}/:id?`}
                component={EditOrderContainer}
            />
        </Switch>
        <BackTop>
            <div className="ant-back-top-inner">
                <img src={top} alt="top" />
            </div>
        </BackTop>
    </div>
);

export default PersonalAreaView;
